import React from 'react';
import { graphql } from 'gatsby';
import { Card, Box, Heading, Text } from 'rebass';
import FiCheck from '@meronex/icons/fi/FiCheck';
import Marked from 'marked';

import LinkButton, { LinkButtonProps } from '../link-button/link-button';
import MainContainer from '../container/container';
import { useThemeUI } from 'theme-ui';

export const PricingSectionQuery = graphql`
  fragment PricingSection on ContentfulPricingSection {
    id
    title
    header
    priceSubheader: subheader {
      subheader
    }
    pricingCards {
      blurb {
        blurb
      }
      dollarAmount
      dollarPeriod
      tag
      features
      featureBackgroundColor
      cta {
        ...Links
      }
    }
    footerTag
    footerBlurb {
      footerBlurb
    }
    cta {
      ...Links
    }
    topBackgroundColor
    bottomBackgroundColor
    footerBackgroundColor
    topTextColor
  }
`;

interface PricingSectionProps {
  data: {
    id: string;
    title: string;
    header: string;
    priceSubheader: {
      subheader: string;
    };
    pricingCards: Array<{
      blurb: {
        blurb: string;
      };
      dollarAmount: string;
      dollarPeriod: string;
      tag: string;
      features: string[];
      featureBackgroundColor: string;
      cta: LinkButtonProps;
    }>;
    footerTag: string;
    footerBlurb: {
      footerBlurb: string;
    };
    cta: LinkButtonProps;
    topBackgroundColor: string;
    bottomBackgroundColor: string;
    footerBackgroundColor: string;
    topTextColor: string;
  };
}

const PricingSection = ({
  data: {
    topTextColor,
    topBackgroundColor,
    bottomBackgroundColor,
    footerBackgroundColor,
    footerTag,
    footerBlurb,
    title,
    header,
    priceSubheader,
    pricingCards,
    cta
  }
}: PricingSectionProps) => {
  const { theme } = useThemeUI();
  return (
    <Box as="section" mb={[5, 6]}>
      <Box
        backgroundColor={topBackgroundColor}
        width="100%"
        textAlign="center"
        pt={5}
        sx={{
          position: 'relative',
          '&::after': {
            content: '""',
            zIndex: 0,
            position: 'absolute',
            height: '120px',
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: bottomBackgroundColor
          }
        }}
      >
        <MainContainer display="block">
          <div>
            <Box mb={5}>
              <Text variant="label" color={topTextColor} mb={0}>
                {title}
              </Text>
              <Heading variant="heroHeading" color={topTextColor} mt={0} mb={2}>
                {header}
              </Heading>
              <Text color={topTextColor}>{priceSubheader.subheader}</Text>
            </Box>
            <Box
              display="grid"
              sx={{
                gridAutoFlow: ['row', 'row', 'column'],
                columnGap: theme.space[3]
              }}
            >
              {pricingCards.map((pricingCard, idx) => (
                <Box
                  key={`${pricingCard.tag}-${idx}`}
                  backgroundColor="white"
                  textAlign="left"
                  sx={{
                    borderRadius: '8px',
                    boxShadow: '0 0 5px 3px rgba(0, 0, 0, .25)',
                    zIndex: 1
                  }}
                  mb={[4, 4, 0]}
                >
                  <Box p={4}>
                    <Box
                      backgroundColor="light-blue-200"
                      color="primary"
                      display="inline-block"
                      px={3}
                      py={1}
                      sx={{
                        borderRadius: '100px'
                      }}
                    >
                      <Text variant="label" fontSize="12px">
                        {pricingCard.tag}
                      </Text>
                    </Box>
                    <Box>
                      <Heading
                        display="inline-block"
                        variant="heroHeading"
                      >{`$${pricingCard.dollarAmount}`}</Heading>
                      <Heading
                        display="inline-block"
                        variant="subheading"
                        color="textGray"
                      >{`/${pricingCard.dollarPeriod}`}</Heading>
                    </Box>
                    <Heading
                      variant="body"
                      color="textGray"
                      my={-3}
                      dangerouslySetInnerHTML={{
                        __html: Marked(
                          pricingCard.blurb && pricingCard.blurb.blurb,
                          { breaks: true }
                        )
                      }}
                    />
                  </Box>
                  <Box
                    backgroundColor={pricingCard.featureBackgroundColor}
                    px={4}
                    py={4}
                    sx={{
                      borderBottomLeftRadius: '8px',
                      borderBottomRightRadius: '8px'
                    }}
                  >
                    <Box mb={4}>
                      {pricingCard.features.map((feature, idx) => (
                        <Box key={`${feature}-${idx}`} mb={3} display="flex">
                          <FiCheck
                            style={{
                              color: theme.colors['green-500'],
                              fontSize: '24px',
                              marginRight: theme.space[3]
                            }}
                          />
                          <Text as="span">{feature}</Text>
                        </Box>
                      ))}
                    </Box>
                    <Box textAlign="center">
                      <LinkButton {...cta} width="100%" />
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
          </div>
        </MainContainer>
      </Box>
      <Box backgroundColor={bottomBackgroundColor} py={4}>
        <MainContainer display="block">
          <Box
            display="grid"
            alignItems="center"
            backgroundColor={footerBackgroundColor}
            p={4}
            sx={{
              gridAutoFlow: ['row', 'row', 'column'],
              gridTemplateColumns: ['none', 'none', '1fr auto'],
              columnGap: theme.space[6],
              borderRadius: '8px'
            }}
          >
            <Box>
              <Box
                backgroundColor="white"
                color="text"
                display="inline-block"
                px={3}
                py={1}
                sx={{
                  borderRadius: '100px'
                }}
              >
                <Text variant="label" fontSize="12px">
                  {footerTag}
                </Text>
              </Box>
              <Text
                variant="renderedMarkdown"
                dangerouslySetInnerHTML={{
                  __html: Marked(footerBlurb && footerBlurb.footerBlurb, {
                    breaks: true
                  })
                }}
              />
            </Box>
            <LinkButton {...cta} />
          </Box>
        </MainContainer>
      </Box>
    </Box>
  );
};

export default PricingSection;
