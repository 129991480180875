import React from 'react';
import { graphql } from 'gatsby';
import { Box } from '@chakra-ui/react';

import MainContainer from '../container/container';
import ShowMarkdown from '../shared/ShowMarkdown';

interface TextSectionProps {
  data: {
    body?: {
      body?: string;
    };
  };
}

export const textSectionQuery = graphql`
  fragment TextSection on ContentfulTextSection {
    id
    body {
      body
    }
  }
`;

const TextSection = ({ data: { body, ...rest } }: TextSectionProps) => {
  return (
    <Box
      as="section"
      py={{
        base: 8,
        lg: 4
      }}
    >
      <MainContainer>
        <Box>
          <ShowMarkdown markdown={body && body.body}></ShowMarkdown>
        </Box>
      </MainContainer>
    </Box>
  );
};

export default TextSection;
