import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

import MainContainer from '../container/container';

import {
  Box,
  Button,
  Center,
  Divider,
  Heading,
  Img,
  SimpleGrid,
  Flex,
  Stack,
  Text,
  useColorModeValue as mode
} from '@chakra-ui/react';

import LinkButton, { LinkButtonProps } from '../link-button/link-button';
import { ContentBlockProps } from '../content-block/content-block';
import theme from '../../../../gatsby-current-client/src/gatsby-plugin-theme-ui';

interface CalendlySectionProps {
  data: {
    backgroundColor?: string;
    color?: string;
    calendlyTextColor: string;
    calendlyUrl: string;
  };
}

export const CalendlySectionQuery = graphql`
  fragment CalendlySection on ContentfulCalendlySection {
    id
    backgroundColor
    color
    calendlyTextColor
    calendlyUrl
  }
`;

const CalendlySection = ({
  data: {
    backgroundColor,
    color,

    calendlyTextColor,
    calendlyUrl
  }
}: CalendlySectionProps) => {
  useEffect(() => {
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.setAttribute(
      'src',
      'https://assets.calendly.com/assets/external/widget.js'
    );
    script.setAttribute('defer', '');
    head.appendChild(script);

    return () => {
      head.removeChild(script);
    };
  }, []);

  return (
    <Box as="section" sx={{ overflow: 'hidden', backgroundColor, color }}>
      <MainContainer>
        <Box flex={1}>
          {calendlyUrl && (
            <Box pt={[10, null, null, 20]}>
              <Box
                className="calendly-inline-widget"
                data-url={`${calendlyUrl}?text_color=${
                  theme.colors[calendlyTextColor].split('#')[1]
                }&primary_color=${theme.colors.primary.split('#')[1]}`}
                mx="auto"
                sx={{
                  marginTop: 0,
                  boxShadow: 'none',
                  minWidth: ['xs', null, 'md', 'lg'],
                  height: [1100, null, 1000, 850],
                  overflowY: 'hidden'
                }}
              />
            </Box>
          )}
        </Box>
      </MainContainer>
    </Box>
  );
};

export default CalendlySection;
