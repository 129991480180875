import { graphql } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

import MainContainer from '../container/container';

import {
  Box,
  Button,
  Center,
  Divider,
  Heading,
  Img,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue as mode
} from '@chakra-ui/react';
import * as React from 'react';

interface BasicSectionProps {
  data: {
    header: string;
    paragraph?: {
      paragraph?: string;
    };
    image?: {
      gatsbyImageData: IGatsbyImageData;
      title: string;
      description: string;
    };
    textColor: string;
    backgroundColor: string;
  };
}

export const BasicSectionQuery = graphql`
  fragment BasicSection on ContentfulBasicSection {
    image {
      title
      description
      gatsbyImageData
    }
    header
    paragraph {
      paragraph
    }
    textColor
    backgroundColor
  }
`;

const BasicSection = ({
  data: { image, header, paragraph, textColor, backgroundColor = 'background' }
}: BasicSectionProps) => {
  return (
    <Box as="section" bg={backgroundColor} py="20">
      <MainContainer>
        <Stack direction="column" w="full">
          {image && (
            <Center mb={8}>
              <Box m="auto" h={48} rounded="md">
                <GatsbyImage
                  objectFit="contain"
                  style={{
                    display: 'block'
                  }}
                  alt="currentclient image"
                  image={image.gatsbyImageData}
                />
              </Box>
            </Center>
          )}

          <Box width="full">
            <Text
              textStyle="header1"
              maxW={{ lg: '2xl' }}
              mx="auto"
              textAlign="center"
              color={textColor}
            >
              {header}
            </Text>
          </Box>

          {paragraph?.paragraph && (
            <Box width="full">
              <Text
                textStyle="body"
                maxW={{ lg: '2xl' }}
                mx="auto"
                color={textColor}
                textAlign="center"
              >
                {paragraph?.paragraph}
              </Text>
            </Box>
          )}
        </Stack>
      </MainContainer>
    </Box>
  );
};

export default BasicSection;
