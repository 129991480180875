import * as React from 'react';

import {
  Heading,
  SimpleGrid,
  Stack,
  Flex,
  Box,
  Text,
  useColorModeValue
} from '@chakra-ui/react';
import { graphql } from 'gatsby';
import CountUp from 'react-countup';
import LazyLoad from 'react-lazyload';
import Marked from 'marked';
import { Stat } from './Stat';
import MainContainer from '../container/container';
import LinkButton, { LinkButtonProps } from '../link-button/link-button';
import theme from '../../../../gatsby-current-client/src/gatsby-plugin-theme-ui';

interface StatsSectionProps {
  data: {
    id: string;
    header?: string;
    subheader?: string;
    paragraph?: {
      paragraph?: string; // Look into refactoring markdown properties so that markdown conversion happens in graphql
    };
    stats?: IStat[];
    links?: LinkButtonProps[];
    backgroundColor?: 'text' | 'primary' | 'secondary' | 'background'; // ToDo: refactor color options into one place
    color?: 'text' | 'primary' | 'secondary' | 'background';
    numberColor?: 'text' | 'primary' | 'secondary' | 'background';
  };
}

interface IStat {
  id: string;
  numberPrefix?: string;
  numberSuffix?: string;
  number: number;
  text: {
    text: string;
  };
  decimalPlaces?: number;
  animationDuration?: number;
}

export const statsSectionQuery = graphql`
  fragment StatsSection on ContentfulStatsSection {
    id
    header
    subheader
    paragraph {
      paragraph
    }
    stats {
      id
      numberPrefix
      numberSuffix
      number
      text {
        id
        text
      }
      decimalPlaces
      animationDuration
    }

    links {
      ...Links
    }
    backgroundColor
    color
    numberColor
  }
`;

const StatsSection = ({
  data: {
    id,
    header,
    subheader,
    paragraph,
    stats,

    links,
    backgroundColor,
    color,
    numberColor,
    ...rest
  }
}: StatsSectionProps) => {
  const linearGradients =
    theme.gradients &&
    theme.gradients.filter(gradient => gradient.includes('linear'));

  const availableGradients = [...linearGradients, ...linearGradients];

  return (
    <Box as="section" py="20" color={color}>
      <MainContainer>
        <Box>
          <Box bg={'pri.50'} pt="20" pb="20" rounded="md">
            <Box
              maxW={{ base: 'xl', md: '7xl' }}
              mx="auto"
              px={{ base: '6', md: '8' }}
            >
              <Stack mb="16" maxW="xl">
                {header && <Text textStyle="header1">{header}</Text>}
                {subheader && <Text textStyle="heroSub">{subheader}</Text>}
                {paragraph?.paragraph && (
                  <Text mb={4} variant="heroParagraph" color={color}>
                    {paragraph?.paragraph}
                  </Text>
                )}
              </Stack>
            </Box>
          </Box>

          <Box mt="-24">
            <Box
              maxW={{ base: 'xl', md: '7xl' }}
              mx="auto"
              px={{ base: '6', md: '8' }}
            >
              <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing="6">
                {stats.map(
                  (
                    {
                      id,
                      number,
                      text,
                      animationDuration,
                      decimalPlaces,
                      numberPrefix,
                      numberSuffix
                    },
                    idx
                  ) => (
                    <Box
                      rounded="md"
                      p={4}
                      key={id}
                      bg={useColorModeValue('white', 'gray.700')}
                      color={useColorModeValue('gray.800', 'white')}
                      borderWidth={1}
                      borderColor={useColorModeValue(
                        'background200',
                        'brandSubtle'
                      )}
                    >
                      <LazyLoad once>
                        <Stat
                          key={id}
                          animationDuration={animationDuration}
                          decimalPlaces={decimalPlaces}
                          numberPrefix={numberPrefix}
                          numberSuffix={numberSuffix}
                          title={text.text}
                          value={number}
                          accentColor={availableGradients[idx]}
                        >
                          average use means something nice
                        </Stat>
                      </LazyLoad>
                    </Box>
                  )
                )}
              </SimpleGrid>
            </Box>
          </Box>
        </Box>
      </MainContainer>
    </Box>
  );
};

export default StatsSection;
