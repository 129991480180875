import { Flex, HStack, Box, Img, StackProps, Text } from '@chakra-ui/react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import * as React from 'react';

interface BlogAuthorProps extends StackProps {
  image: {
    gatsbyImageData: IGatsbyImageData;
    title: string;
    description: string;
  };
  name: string;
  role: string;
}

export const BlogAuthor = (props: BlogAuthorProps) => {
  const { image, name, role, ...rest } = props;
  return (
    <HStack spacing="4" {...rest}>
      <Box m="auto" w="10" h="10" rounded="full" overflow="hidden">
        <GatsbyImage
          objectFit="cover"
          style={{
            display: 'block'
          }}
          alt="currentclient image"
          image={image.gatsbyImageData}
        />
      </Box>
      <Flex flex={1} direction="column">
        <Text fontWeight="semibold" fontSize="sm">
          {name}
        </Text>
        <Text fontSize="sm" color="gray.400">
          {role}
        </Text>
      </Flex>
    </HStack>
  );
};
