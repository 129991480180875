// @jsx jsx

import { jsx } from 'theme-ui';
import React from 'react';
import { graphql } from 'gatsby';
import Marked from 'marked';
import { Box, Text, Flex, Heading, Button } from 'rebass';
import { Input, Textarea } from '@rebass/forms';
import { NetlifyForm, Honeypot, Recaptcha } from 'react-netlify-forms';

import MainContainer from '../container/container';

interface ContactSectionProps {
  data: {
    header?: string;
    paragraph?: {
      paragraph?: string;
    };
    sidebar?: {
      sidebar?: string;
    };
  };
}

export const ContactSectionQuery = graphql`
  fragment ContactSection on ContentfulContactSection {
    id
    header
    paragraph {
      paragraph
    }
    sidebar {
      sidebar
    }
  }
`;

const ContactSection = ({
  data: { header, paragraph, sidebar, ...rest }
}: ContactSectionProps) => {
  return (
    <Box mb={[5, 6]} as="section">
      <MainContainer>
        <Flex flexWrap="wrap" width="100%">
          <Box
            width={['100%', 'calc(100% * 7/12)', 'calc(100% * 7/12)']}
            pr={[0, `${(1 / 12) * 100}%`, `${(1 / 12) * 100}%`]}
          >
            {header && <Heading as="h1">{header}</Heading>}
            {paragraph?.paragraph && (
              <Text
                dangerouslySetInnerHTML={{
                  __html: Marked(paragraph?.paragraph, { breaks: true })
                }}
                variant="renderedMarkdown"
                className="contact-paragraph rendered-markdown"
              />
            )}
            <Box className="form-wrapper" py="4">
              <NetlifyForm
                name="contact"
                //action="/thanks"
                honeypotName="bot-field"
                enableRecaptcha
                onSuccess={(response, context) => {
                  context.formRef.current.reset();
                }}
              >
                {({ handleChange, success, error }) => (
                  <Box>
                    <Honeypot />
                    <Recaptcha
                      siteKey={process.env.GATSBY_SITE_RECAPTCHA_KEY}
                      invisible
                    />
                    <Input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Name"
                      onChange={handleChange}
                      required
                      my="3"
                    />
                    <Input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Email"
                      onChange={handleChange}
                      required
                      my="3"
                    />
                    <Input
                      type="tel"
                      name="phone"
                      id="phone"
                      placeholder="Phone"
                      onChange={handleChange}
                      my="3"
                    />
                    <Textarea
                      type="text"
                      name="message"
                      id="message"
                      placeholder="Your message..."
                      rows="4"
                      onChange={handleChange}
                      my="3"
                      style={{
                        minHeight: '120px',
                        resize: 'none',
                        position: 'relative'
                      }}
                    />
                    <Button type="submit">Send Message</Button>
                    {success && (
                      <Box py="4">
                        <Heading as="h4" variant="subheading" py="3">
                          Thank you!
                        </Heading>
                        <Text>We'll be in touch soon.</Text>
                      </Box>
                    )}
                    {error && (
                      <Box py="4">
                        <Heading as="h4" variant="subheading" py="3">
                          Something went wrong!
                        </Heading>
                        <Text>
                          Please give us a call or send us an email to get in
                          touch.
                        </Text>
                      </Box>
                    )}
                  </Box>
                )}
              </NetlifyForm>
            </Box>
          </Box>
          <Box
            width={['100%', 'calc(100% * 4/12)', 'calc(100% * 4/12)']}
            pt={[0, 0, 4]}
          >
            {sidebar?.sidebar && (
              <Text
                dangerouslySetInnerHTML={{
                  __html: Marked(sidebar?.sidebar, { breaks: true })
                }}
                variant="renderedMarkdown"
                className="contact-sidebar rendered-markdown"
              />
            )}
          </Box>
        </Flex>
      </MainContainer>
    </Box>
  );
};

export default ContactSection;
