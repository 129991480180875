import {
  Box,
  Center,
  Text,
  Heading,
  SimpleGrid,
  Stack,
  useColorModeValue as mode
} from '@chakra-ui/react';
import * as React from 'react';

import { graphql } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

import MainContainer from '../container/container';

interface LogoSectionProps {
  data: {
    header?: string;
    subheader?: string;
    logos?: ContentfulFluidImage[];
    backgroundColor?: 'text' | 'primary' | 'secondary' | 'background';
  };
}

interface ContentfulFluidImage {
  id?: string;
  title?: string;
  description?: string;
  gatsbyImageData?: IGatsbyImageData;
}

export const logoSectionQuery = graphql`
  fragment LogoSection on ContentfulLogoSection {
    id
    backgroundColor
    header
    subheader
    logos {
      id
      title
      description
      gatsbyImageData(width: 400, quality: 100)
    }
  }
`;

const LogoSection = ({
  data: {
    backgroundColor,
    header,
    subheader,
    logos,

    ...rest
  }
}: LogoSectionProps) => {
  return (
    <Box as="section" py="20" my={20} bg="pri.50">
      <MainContainer>
        <Box w="full">
          <Stack>
            <Box width="full">
              <Text textStyle="header1" mx="auto" textAlign="center">
                {header}
              </Text>
            </Box>

            <Box width="full">
              <Text
                textStyle="body"
                maxW={{ lg: '2xl' }}
                mx="auto"
                textAlign="center"
              >
                {subheader}
              </Text>
            </Box>
          </Stack>

          <SimpleGrid
            columns={{ base: 1, sm: 2, md: 3 }}
            mt="12"
            spacing="6"
            color={mode('inherit', 'white')}
          >
            {logos.map((logo, idx) => (
              <Center
                key={`${logo.id}-${idx}`}
                py="2"
                px="4"
                minH="32"
                maxH="32"
                overflow="hidden"
                bg={mode('white', 'gray.800')}
                rounded="md"
              >
                <GatsbyImage
                  objectFit="contain"
                  style={{
                    display: 'block',
                    height: '6rem',
                    margin: 'auto'
                  }}
                  image={logo.gatsbyImageData}
                  alt={logo.description}
                />
              </Center>
            ))}
          </SimpleGrid>
        </Box>
      </MainContainer>
    </Box>
  );
};

export default LogoSection;
