import * as React from 'react';
import {
  Flex,
  Box,
  Button,
  Stack,
  HStack,
  Img,
  Center,
  Heading,
  SimpleGrid,
  Text,
  useColorModeValue as mode,
  ButtonGroup,
  Wrap
} from '@chakra-ui/react';

import { graphql } from 'gatsby';
import { IGatsbyImageData, GatsbyImage } from 'gatsby-plugin-image';
import { Grid } from 'theme-ui';

import HiArrowNarrowRight from '@meronex/icons/hi/HiArrowNarrowRight';

import miniSvgDataUri from 'mini-svg-data-uri';
import { renderToString } from 'react-dom/server.browser';

import LinkButton, { LinkButtonProps } from '../link-button/link-button';
import MainContainer from '../container/container';
import { ContentBlockProps } from '../content-block/content-block';
import theme from '../../../../gatsby-current-client/src/gatsby-plugin-theme-ui';

interface TextColumnsSectionProps {
  data: {
    backgroundColor?: string;
    color?: string;
    header?: string;
    subheader?: string;
    textColumns?: Array<ContentBlockProps>;
    columnsPerRow?: number;
    links?: Array<LinkButtonProps>;
  };
}

export const TextColumnsSectionQuery = graphql`
  fragment TextColumnsSection on ContentfulTextColumnsSection {
    id
    backgroundColor
    color
    header
    subheader
    columnsPerRow
    textColumns {
      ...ContentBlock
    }
    links {
      ...Links
    }
  }
`;

const TextColumnsSection = ({
  data: {
    backgroundColor,
    color,
    header,
    subheader,
    textColumns,
    columnsPerRow = 3,
    links
  }
}: TextColumnsSectionProps) => {
  const linearGradients =
    theme.gradients &&
    theme.gradients.filter(gradient => gradient.includes('linear'));

  const availableGradients = [...linearGradients, ...linearGradients];
  return (
    <Box
      as="section"
      py={20}
      sx={{ overflow: 'hidden', backgroundColor, color }}
    >
      <MainContainer>
        <Stack direction="column" w="full">
          <Box width="full">
            <Text
              textStyle="header1"
              maxW={{ lg: '2xl' }}
              mx="auto"
              textAlign="center"
              color={color}
            >
              {header}
            </Text>
          </Box>

          {subheader && (
            <Box width="full">
              <Text
                textStyle="body"
                maxW={{ lg: '2xl' }}
                mx="auto"
                color={color}
                textAlign="center"
              >
                {subheader}
              </Text>
            </Box>
          )}
        </Stack>

        <Box py={[8, 12, 16]} css={{ width: '100%' }}>
          <Grid columns={[1, columnsPerRow]} gap={[4, 5, 6]}>
            {textColumns.map(
              ({ id, blockHeader, blockSubheader, blockParagraph }, i) => (
                <Box
                  as="article"
                  key={id}
                  sx={{
                    position: 'relative',
                    ...(i + 1 !== textColumns.length && {
                      '&::after': {
                        content: `url("${miniSvgDataUri(
                          renderToString(<HiArrowNarrowRight />)
                        )}")`,
                        position: 'absolute',
                        right: '-16%',
                        top: '10%',
                        display: ['none', 'initial']
                      }
                    })
                  }}
                >
                  <Box position="relative" h="16" w="32">
                    <Box
                      position="absolute"
                      bgGradient={availableGradients[i]}
                      opacity={0.2}
                      top="4"
                      right="-4"
                      left="-0"
                      bottom="3"
                      fontSize="2rem"
                      zIndex={0}
                      borderRadius={4}
                      p={2}
                    ></Box>

                    <Center position="relative" zIndex={1} h="full" w="full">
                      <Text
                        mb={3}
                        textStyle="header1"
                        fontSize="3rem"
                        fontWeight="bold"
                      >
                        {blockHeader}
                      </Text>
                    </Center>
                  </Box>

                  <Text mt={[2, null, 8]} textStyle="accentHeader">
                    {blockSubheader}
                  </Text>
                  <Text textStyle="body">{blockParagraph.blockParagraph}</Text>
                </Box>

                // <Box
                // as="article"
                // key={id}
                // sx={{
                //   position: 'relative',
                //   ...(i + 1 !== textColumns.length && {
                //     '&::after': {
                //       content: `url("${miniSvgDataUri(
                //         renderToString(<HiArrowNarrowRight />)
                //       )}")`,
                //       position: 'absolute',
                //       right: '-16%',
                //       top: '10%',
                //       display: ['none', 'initial']
                //     }
                //   })
                // }}
                // >
                //   <Text
                //     mb={3}
                //     textStyle="header1"
                //     bgGradient={availableGradients[i]}
                //   >
                //     {blockHeader}
                //   </Text>
                // <Text textStyle="subheader">{blockSubheader}</Text>
                // <Text textStyle="body">{blockParagraph.blockParagraph}</Text>
                // </Box>
              )
            )}
          </Grid>
        </Box>
      </MainContainer>
    </Box>
  );
};

export default TextColumnsSection;
