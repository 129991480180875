import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { IGatsbyImageData, GatsbyImage } from 'gatsby-plugin-image';
import {
  Flex,
  Heading,
  Stack,
  Grid,
  useColorModeValue as mode,
  Box,
  Button,
  Text,
  SimpleGrid,
  HStack
} from '@chakra-ui/react';

import LinkButton, { LinkButtonProps } from '../link-button/link-button';
import MainContainer from '../container/container';
import Icon from '../icon/icon';

interface UseCasesSectionProps {
  data: {
    useCases?: Array<{
      label: string;
      quote: {
        quote?: string;
      };
      attributionName: string;
      attributionTitle: string;
      image: {
        gatsbyImageData: IGatsbyImageData;
        title: string;
        description: string;
      };
      cta?: LinkButtonProps;
    }>;
    blurb?: string;
  };
}

export const UseCasesSectionQuery = graphql`
  fragment UseCasesSection on ContentfulUseCasesSection {
    id
    useCases {
      label
      quote {
        quote
      }
      attributionName
      attributionTitle
      image {
        title
        description
        gatsbyImageData(width: 480, quality: 100)
      }
      cta {
        ...Links
      }
    }
    blurb
  }
`;

const outlineSx = {
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: 'background200',
  rounded: 'md',
  backgroundColor: 'white'
} as const;

const labelSx = {
  background: 'white',
  justifyContent: 'flex-start',
  color: 'black',
  rounded: 'md',
  px: 3,
  py: 3,
  textAlign: 'left',
  '&:hover': {
    backgroundColor: 'background100',
    cursor: 'pointer'
  }
} as const;

const activeLabelSx = {
  ...labelSx,
  color: 'white',
  background: 'black',
  '&:hover': {
    opacity: 0.8,
    background: 'black',
    cursor: 'pointer'
  }
} as const;

const UseCasesSection = ({
  data: { useCases, blurb }
}: UseCasesSectionProps) => {
  const [activeUseCaseIndex, setActiveUseCaseIndex] = useState(0);

  return (
    <Box
      as="section"
      overflow="hidden"
      pt="20"
      pb="20"
      sx={{
        position: 'relative',
        '&:before': {
          zIndex: 0,
          content: '""',
          position: 'absolute',
          display: 'block',
          top: 48,
          left: '0',
          right: '0',
          bottom: 20,
          bg: 'background100'
        }
      }}
    >
      <MainContainer>
        <SimpleGrid
          columns={[1, null, 2]}
          width="full"
          mx="auto"
          mb={4}
          zIndex={1}
          gridTemplateColumns={['none', 'none', '33% 1fr']}
          gridTemplateRows={['150px 1fr', '150px 1fr', 'none']}
          columnGap={12}
          rowGap={4}
        >
          <Stack
            overflow="auto"
            p={4}
            sx={{
              ...outlineSx,
              rowGap: 1,
              maxHeight: ['200px', '200px', 'none']
            }}
          >
            <Text
              textStyle="accentHeader"
              pl={2}
              color={mode('gray.400', 'gray.600')}
            >
              Industry
            </Text>

            {useCases.map((useCase, i) => (
              <Button
                key={i}
                sx={i === activeUseCaseIndex ? activeLabelSx : labelSx}
                onClick={() => {
                  setActiveUseCaseIndex(i);
                }}
              >
                <Text variant="body">{useCase.label}</Text>
              </Button>
            ))}
          </Stack>

          {useCases.map((useCase, i) => (
            <Grid
              key={i}
              display={i === activeUseCaseIndex ? 'inherit' : 'none'}
              alignItems={['center', null, 'center']}
              justifyItems={['center', null, 'flex-start']}
              sx={{
                ...outlineSx
              }}
              columnGap={8}
              rowGap={8}
              gridAutoFlow={['none', 'none', 'none']}
              gridTemplateColumns={['auto', 'auto', 'auto auto']}
              gridTemplateRows={['auto auto', 'auto auto', 'auto auto']}
              py={[12, null, 12]}
              px={[8, null, 12]}
            >
              {useCase.image && (
                <Box
                  display={['none', null, 'block']}
                  w={[null, null, 32, 48]}
                  rounded="md"
                  overflow="hidden"
                >
                  <GatsbyImage
                    objectFit="cover"
                    style={{
                      display: 'block',
                      height: '100%',
                      width: '100%'
                    }}
                    alt={useCase.attributionName}
                    image={useCase.image.gatsbyImageData}
                  />
                </Box>
              )}

              <Box>
                <Text fontSize={48} lineHeight="1rem">
                  &ldquo;
                </Text>
                <Text textStyle="body">{useCase.quote.quote}</Text>

                <HStack mt="4" alignItems="flex-end" spacing={['2', null, '0']}>
                  {/* Image */}
                  {useCase.image && (
                    <Box
                      display={['block', null, 'none']}
                      w={'12'}
                      h={'16'}
                      rounded="lg"
                      overflow="hidden"
                    >
                      <GatsbyImage
                        objectFit="cover"
                        style={{
                          display: 'block',
                          height: '100%',
                          width: '100%'
                        }}
                        alt={useCase.attributionName}
                        image={useCase.image.gatsbyImageData}
                      />
                    </Box>
                  )}

                  <Stack spacing={0}>
                    <Text
                      as="cite"
                      fontStyle="normal"
                      fontSize="md"
                      fontWeight="extrabold"
                    >
                      {useCase.attributionName}
                    </Text>
                    <Text
                      textStyle="accentHeader"
                      color={mode('gray.600', 'gray.400')}
                    >
                      {useCase.attributionTitle}
                    </Text>
                  </Stack>
                </HStack>
              </Box>

              {useCase.cta && (
                <Box mr="auto">
                  <LinkButton
                    type="button"
                    {...useCases[activeUseCaseIndex].cta}
                  />
                </Box>
              )}
            </Grid>
          ))}
        </SimpleGrid>
        <Text
          textAlign="center"
          textStyle="accentHeader"
          color={mode('gray.400', 'gray.400')}
          mx="auto"
          pt="4"
          pb="12"
          zIndex={1}
        >
          {blurb}
        </Text>
      </MainContainer>
    </Box>
  );
};

export default UseCasesSection;
