import { graphql, Link, useStaticQuery } from 'gatsby';
import ReactPaginate from 'react-paginate';

import MainContainer from '../container/container';

import {
  Box,
  Heading,
  SimpleGrid,
  Text,
  useColorModeValue as mode
} from '@chakra-ui/react';
import * as React from 'react';
import { BlogCard, IBlogPost } from './BlogCard';

export const BlogPostsSectionQuery = graphql`
  fragment BlogPostsSection on ContentfulBlogPostsSection {
    id
    header
    subheader
  }
`;

type Props = {
  data: {
    header: string;
    subheader: string;
  };
};

const BlogPostsSection = ({ data: { header, subheader } }: Props) => {
  const [activePage, setActivePage] = React.useState(0);

  const data = useStaticQuery(graphql`
    query {
      allContentfulBlogPost(
        filter: { isDummyPage: { ne: true }, node_locale: { eq: "en-US" } }
      ) {
        nodes {
          id
          slug
          metaTitle
          metaDescription
          estReadTime
          metaImage {
            gatsbyImageData(height: 400, quality: 100)
          }
          createdAt
          author {
            title
            name
            image {
              title
              description
              gatsbyImageData(height: 48, quality: 100)
            }
          }
        }
      }
    }
  `);

  const blogPosts: IBlogPost[] = data.allContentfulBlogPost.nodes;
  const blogPostsPerPage = 12;
  const blogPostsStartIndex = activePage * blogPostsPerPage;
  const activeBlogPosts = blogPosts.slice(
    blogPostsStartIndex,
    blogPostsStartIndex + blogPostsPerPage
  );
  const blogPostPageCount = Math.floor(blogPosts.length / blogPostsPerPage) + 1;

  return (
    <Box as="section" pt="12" pb="24">
      <MainContainer>
        <Box w="full">
          <Box textAlign="center" mx="auto">
            <Heading as="h1" width="100%" variant="heroHeading">
              {header}
            </Heading>
            <Text mt="4" fontSize="lg" color={mode('gray.600', 'gray.400')}>
              {subheader}
            </Text>
          </Box>
          <SimpleGrid mt="14" mb={8} columns={{ base: 1, lg: 3 }} spacing="14">
            {activeBlogPosts.map((blog, idx) => (
              <BlogCard key={idx} data={blog} />
            ))}
          </SimpleGrid>
          {/* {blogPostPageCount > 1 && (
            <Box
              mx="auto"
              textAlign="center"
              pt={12}
              pb={8}
              sx={{
                '& ul': {
                  listStyle: 'none',
                  display: 'block',
                  margin: 'auto',
                  userSelect: 'none'
                },
                '& li': {
                  display: 'inline-block',
                  paddingY: 4,
                  paddingX: 6,
                  borderRadius: '4',

                  '&.disabled, &.disabled:hover': {
                    color: 'background200',
                    cursor: 'not-allowed',
                    pointerEvents: 'none',
                    outline: 'none',
                    outlineColor: 'transparent'
                  },

                  '& > a': {
                    fontSize: 'lg'
                  },

                  '&:hover': {
                    cursor: 'pointer',
                    bg: 'pri.50'
                  },

                  '&.selected': {
                    color: 'primary'
                  }
                }
              }}
            >
              <ReactPaginate
                pageCount={blogPostPageCount}
                pageRangeDisplayed={blogPostsPerPage}
                marginPagesDisplayed={1}
                onPageChange={data => {
                  setActivePage(data.selected);
                }}
              />
            </Box>
          )} */}
        </Box>
      </MainContainer>
    </Box>
  );
};

export default BlogPostsSection;
