import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

interface Props {
  description: string;
  lang: string;
  meta?: {};
  image: string;
  title: string;
  pathname: string;
  noIndexNoFollow: boolean;
}

const SEO = ({
  description,
  lang,
  meta,
  image: metaImage,
  title,
  pathname,
  noIndexNoFollow
}: Props) => {
  const {
    site: {
      siteMetadata: { title: siteTitle, description: siteDescription, siteUrl }
    }
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
          }
        }
      }
    `
  );

  const isHomepage = pathname === '/';
  const metaDescription = description || siteDescription;
  const image = metaImage;
  const canonical = isHomepage ? siteUrl : pathname && `${siteUrl}${pathname}`;
  const language = lang || 'EN';
  const metaTitle = isHomepage ? siteTitle : title;
  const titleTemplate = isHomepage ? '' : `%s | ${siteTitle}`;

  return (
    <Helmet
      htmlAttributes={{
        lang: language
      }}
      title={metaTitle}
      titleTemplate={titleTemplate}
      link={
        canonical
          ? [
              {
                rel: 'canonical',
                href: canonical
              }
            ]
          : []
      }
      meta={
        [
          {
            name: `og:site_name`,
            content: siteTitle
          },
          {
            name: `description`,
            content: metaDescription
          },
          {
            property: `og:title`,
            content: title
          },
          {
            property: `og:description`,
            content: metaDescription
          },
          {
            property: `og:type`,
            content: `website`
          },
          {
            name: `twitter:creator`,
            content: ''
          },
          {
            name: `twitter:title`,
            content: title
          },
          {
            name: `twitter:description`,
            content: metaDescription
          },
          {
            name: `twitter:image:alt`,
            content: metaDescription || siteTitle
          }
        ]
          .concat(
            image
              ? [
                  {
                    property: `og:image`,
                    content: image
                  },
                  {
                    name: `twitter:card`,
                    content: 'summary_large_image'
                  }
                ]
              : [
                  {
                    name: 'twitter:card',
                    content: `summary`
                  }
                ]
          )
          .concat(
            noIndexNoFollow
              ? [
                  {
                    name: `robots`,
                    content: `noindex`
                  },
                  {
                    name: `robots`,
                    content: `nofollow`
                  }
                ]
              : []
          )
        // .concat(meta)
      }
    />
  );
};

export default SEO;
