import {
  Divider,
  Image,
  OrderedList,
  Text,
  UnorderedList,
  HStack,
  Icon,
  Link
} from '@chakra-ui/react';
import { HiOutlineLightBulb } from '@meronex/icons/hi/';
import ChakraUIRenderer from 'chakra-ui-markdown-renderer';
import React from 'react';
import ReactMarkdown from 'react-markdown';

const markdownTheme = {
  h1: props => {
    const { children } = props;
    return (
      <Text fontSize="2xl" mb={4} fontWeight="bold">
        {children}
      </Text>
    );
  },
  h2: props => {
    const { children } = props;
    return (
      <Text fontSize="xl" mb={2} fontWeight="bold">
        {children}
      </Text>
    );
  },
  h3: props => {
    const { children } = props;
    return (
      <Text fontSize="lg" mb={1} fontWeight="bold">
        {children}
      </Text>
    );
  },
  p: props => {
    const { children } = props;
    return (
      <Text fontSize="md" mb={6} lineHeight="1.75">
        {children}
      </Text>
    );
  },
  blockquote: props => {
    const { children, ...rest } = props;
    return (
      <HStack
        mb={6}
        bg="pri.50"
        spacing={4}
        py={4}
        px={4}
        borderRadius={4}
        textStyle="accent"
        position="relative"
        {...rest}
      >
        <Icon fontSize="4xl" color="sec.300" as={HiOutlineLightBulb}></Icon>
        {children}
      </HStack>
    );
  },
  img: props => {
    return (
      <Image
        {...props}
        mx="auto"
        borderRadius={4}
        borderWidth={1}
        borderStyle="solid"
        borderColor="gray.200"
      ></Image>
    );
  },
  ul: props => {
    const { children } = props;
    return (
      <UnorderedList fontSize="md" mb={6} pl={6} spacing={2}>
        {children}
      </UnorderedList>
    );
  },
  a: props => {
    const { children } = props;
    return (
      <Link mb={6} {...props} color="sec.700" fontWeight="bold">
        {children}
      </Link>
    );
  },
  ol: props => {
    const { children } = props;
    return (
      <OrderedList fontSize="md" mb={6} pl={6} spacing={2}>
        {children}
      </OrderedList>
    );
  },
  hr: props => {
    return <Divider my={6}></Divider>;
  }
};

interface IProps {
  markdown: string;
}

const ShowMarkdown = ({ markdown }: IProps) => {
  return (
    <ReactMarkdown
      components={ChakraUIRenderer(markdownTheme)}
      children={markdown}
    />
  );
};

export default ShowMarkdown;
