import {
  Flex,
  useColorModeValue as mode,
  Box,
  Heading,
  Text,
  SimpleGrid,
  Stack,
  HStack,
  Center,
  VStack
} from '@chakra-ui/react';

import { IGatsbyImageData, GatsbyImage } from 'gatsby-plugin-image';
import * as React from 'react';
import MainContainer from '../container/container';

import { graphql } from 'gatsby';

import Icon from '../icon/icon';

interface ProblemAndSolutionSectionProps {
  data: {
    header: string;
    subheader: string; // Make header h1 style if subheader is set
    items: Array<{
      id: string;
      text: string;
      icon: any;
    }>;
    testimonials: Array<{
      id: string;
      quote?: {
        quote?: string;
      };
      attributionName?: string;
      attributionTitle?: string;
      image?: {
        gatsbyImageData: IGatsbyImageData;
        title: string;
        description: string;
      };
    }>;
  };
}

export const ProblemAndSolutionSectionQuery = graphql`
  fragment ProblemAndSolutionSection on ContentfulProblemAndSolutionSection {
    id
    header
    subheader
    items {
      id
      text
      icon {
        ...Icon
      }
    }
    testimonials {
      id
      quote {
        quote
      }
      attributionName
      attributionTitle
      image {
        title
        description
        gatsbyImageData(height: 300, quality: 100)
      }
    }
  }
`;

const ProblemAndSolutionSection = ({
  data: { header, subheader, items, testimonials, ...rest }
}: ProblemAndSolutionSectionProps) => {
  return (
    <Box as="section" py="20">
      <Box bg="gray.800" color="white" pt="24" pb="12rem">
        <MainContainer>
          <Stack spacing={4}>
            <Text textStyle="header1" maxW={{ base: 'unset', lg: '4xl' }}>
              {header}
            </Text>
            <Text
              fontSize={['sm', null, 'lg']}
              textStyle="accentHeader"
              maxW={{ base: 'unset', lg: '800px' }}
            >
              {subheader}
            </Text>
          </Stack>
          <SimpleGrid
            columns={{ base: 1, md: 2, lg: 4 }}
            spacing={{ base: '12', md: '16', lg: '24' }}
            mt={{ base: '12', md: '20' }}
          >
            {items.map((item, idx) => (
              <Box key={idx}>
                <Box position="relative" h="16" w="16">
                  <Box
                    position="absolute"
                    opacity=".4"
                    bg="gray.700"
                    top="4"
                    right="-4"
                    left="-0"
                    bottom="3"
                    color="black"
                    fontSize="2rem"
                    zIndex={0}
                    rounded="md"
                    p={2}
                  ></Box>

                  <Center
                    position="relative"
                    zIndex={1}
                    h="full"
                    w="full"
                    textAlign="center"
                  >
                    <Icon
                      {...item.icon}
                      iconColor="blue-200"
                      iconSize="3.5rem"
                      mx="auto"
                    />
                  </Center>
                </Box>

                <Stack mt="2">
                  <Text pr="6" textStyle="body">
                    {item.text}
                  </Text>
                </Stack>
              </Box>
            ))}
          </SimpleGrid>
        </MainContainer>
      </Box>
      <Box mt={{ base: '-12', md: '-20' }}>
        <MainContainer>
          <SimpleGrid spacing="14" columns={{ base: 1, lg: 2 }}>
            {testimonials.map((testimonial, i) => (
              <Box
                key={testimonial.id}
                as="blockquote"
                rounded="md"
                bg={mode('white', 'gray.700')}
                color={mode('gray.800', 'white')}
                borderWidth={1}
                borderColor={mode('background200', 'brandSubtle')}
                px="10"
                py="8"
              >
                <Flex mb="6">
                  {/* <Img
                    mt="-12"
                    bg={mode('white', 'gray.700')}
                    objectFit="cover"
                    w="24"
                    h="24"
                    rounded="full"
                    color={mode('white', 'gray.700')}
                    shadow="0 0 0 10px currentColor"
                    src={image}
                    alt={name}
                  /> */}

                  <Box
                    mt="-12"
                    bg={mode('white', 'gray.700')}
                    objectFit="cover"
                    rounded="md"
                    overflow="hidden"
                    color={mode('white', 'gray.700')}
                    shadow="0 0 0 8px currentColor"
                  >
                    <GatsbyImage
                      style={{
                        display: 'block',
                        height: '8rem'
                      }}
                      alt={testimonial.attributionName}
                      image={testimonial.image.gatsbyImageData}
                    />
                  </Box>

                  <Box marginStart="5">
                    <Text
                      as="cite"
                      fontStyle="normal"
                      fontSize="md"
                      fontWeight="extrabold"
                    >
                      {testimonial.attributionName}
                    </Text>
                    <Text
                      mt="1"
                      textStyle="accentHeader"
                      color={mode('gray.600', 'gray.400')}
                    >
                      {testimonial.attributionTitle}
                    </Text>
                  </Box>
                </Flex>

                <Box>
                  <Text fontSize={48} lineHeight="1rem">
                    &ldquo;
                  </Text>
                  <Text textStyle="body">{testimonial.quote.quote}</Text>
                </Box>
              </Box>
            ))}
          </SimpleGrid>
        </MainContainer>
      </Box>
    </Box>
  );
};

export default ProblemAndSolutionSection;
