import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  List,
  ListItem,
  Stack,
  Text
} from '@chakra-ui/react';
import { useLocation } from '@reach/router';
import { graphql, Link as GatsbyLink } from 'gatsby';
import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const WIDTH_PROPS = {
  maxW: { base: 'full', lg: '16rem' },
  width: { base: 'full' }
};

interface NavigationHeaderProps {
  id: string;
  label: string;
}

interface NavigationItemProps {
  id: string;
  label: string;
  page: {
    slug: string;
  };
  contentfulchildren: Array<NavigationItemProps>;
}

interface NavigationGroupProps {
  id: string;
  label: string;
  header: NavigationHeaderProps;
  items: Array<NavigationItemProps>;
}

interface SideNavigationProps {
  data: {
    rootNavigationGroups: Array<NavigationGroupProps>;
  };
}

export const SideNavigationQuery = graphql`
  fragment NavigationItem on ContentfulNavigationItem {
    id
    label
    page {
      slug
    }
  }

  fragment NavigationHeader on ContentfulNavigationHeader {
    id
    label
  }

  fragment NavigationGroup on ContentfulNavigationGroup {
    id
    header {
      ...NavigationHeader
    }
    items {
      ...NavigationItem
      contentfulchildren {
        ...NavigationItem
      }
    }
  }

  fragment SideNavigation on ContentfulSideNavigation {
    rootNavigationGroups {
      ...NavigationGroup
    }
  }
`;

interface ISideLinkProps {
  slug: string;
  label: string;
  depth: number;
}

const SideLink = ({ slug, label, depth }: ISideLinkProps) => {
  const location = useLocation();
  const [isActive, set_isActive] = React.useState<boolean>(false);

  React.useEffect(() => {
    set_isActive(slug === location.pathname);
  }, [slug]);

  return (
    <GatsbyLink to={slug}>
      <Text
        borderRadius={4}
        px={2}
        py={1}
        fontSize="sm"
        backgroundColor={isActive ? 'pri.100' : 'inherit'}
        _hover={{
          bg: isActive ? 'pri.100' : 'background100'
        }}
        isTruncated={true}
        w="full"
      >
        {label}
      </Text>
    </GatsbyLink>
  );
};

interface INavigationGroupProps {
  group: NavigationGroupProps;
}

const NavigationGroup = ({ group }: INavigationGroupProps) => {
  const location = useLocation();

  return (
    <Stack spacing={0} {...WIDTH_PROPS}>
      <Text
        isTruncated={true}
        {...WIDTH_PROPS}
        textStyle="accentHeader"
        px={2}
        py={1}
      >
        {group.header.label}
      </Text>

      <List spacing={0}>
        {group.items.map(navigationItem => {
          let isHasActiveItemInChildren = false;
          if (navigationItem.contentfulchildren) {
            isHasActiveItemInChildren = navigationItem?.contentfulchildren.some(
              navigationItem => navigationItem?.page?.slug === location.pathname
            );
          }

          return (
            <ListItem key={navigationItem.id}>
              {navigationItem.page ? (
                <SideLink
                  slug={navigationItem.page.slug}
                  label={navigationItem.label}
                  depth={0}
                />
              ) : (
                <Accordion
                  key={navigationItem.id}
                  allowToggle
                  allowMultiple
                  defaultIndex={isHasActiveItemInChildren && [0]}
                >
                  <AccordionItem
                    borderTopWidth={0}
                    _last={{ borderBottomWidth: 0 }}
                  >
                    <AccordionButton
                      borderRadius={4}
                      p={2}
                      _focus={{
                        boxShadow: 0
                      }}
                      _expanded={{ bg: 'gray.100' }}
                    >
                      <Box flex="1" textAlign="left">
                        {navigationItem.label}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>

                    <AccordionPanel
                      mt={2}
                      ml={2}
                      borderLeftWidth={4}
                      p={0}
                      pl={2}
                      borderColor="gray.100"
                    >
                      {navigationItem.contentfulchildren && (
                        <List>
                          {navigationItem.contentfulchildren.map(
                            secondNavigationItem => (
                              <ListItem key={secondNavigationItem.id}>
                                <SideLink
                                  slug={secondNavigationItem.page.slug}
                                  label={secondNavigationItem.label}
                                  depth={1}
                                />
                              </ListItem>
                            )
                          )}
                        </List>
                      )}
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              )}
            </ListItem>
          );
        })}
      </List>
    </Stack>
  );
};

const SideNavigation = ({
  data: { rootNavigationGroups }
}: SideNavigationProps) => {
  return (
    <Box height="full">
      <Box
        minWidth="14rem"
        maxHeight={['200px', '100%']}
        overflow="auto"
        py={4}
        sx={{
          top: 20,
          position: 'sticky'
        }}
      >
        <Stack spacing={8}>
          {rootNavigationGroups.map(navigationGroup => {
            return (
              <Box key={navigationGroup.id}>
                <NavigationGroup group={navigationGroup}></NavigationGroup>
              </Box>
            );
          })}
        </Stack>
      </Box>
    </Box>
  );
};

export default SideNavigation;
