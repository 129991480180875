import { Box, Link } from '@chakra-ui/react';
import { graphql, Link as GatsbyLink } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import * as React from 'react';

interface IBlogMediaProps {
  image: {
    gatsbyImageData: IGatsbyImageData;
    title: string;
    description: string;
  };
  slug: string;
}

export const BlogMedia = (props: IBlogMediaProps) => {
  const { image, slug } = props;
  return (
    <Link as={GatsbyLink} to={slug}>
      <Box
        pos="relative"
        cursor="pointer"
        className="group"
        h={{ base: '20rem', lg: '12rem' }}
        overflow="hidden"
        rounded="md"
      >
        <Box
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          transition="all 0.2s"
          _hover={{ transform: 'scale(1.05)' }}
        >
          <GatsbyImage
            objectFit="cover"
            style={{
              display: 'block'
            }}
            alt="Blog media"
            image={image.gatsbyImageData}
          />
        </Box>
      </Box>
    </Link>
  );
};
