import React from 'react';

import {
  BasicSection,
  BlogPostsSection,
  CalendlySection,
  ContactSection,
  FaqSection,
  FeatureGridSection,
  GallerySection,
  HeroSection,
  LogoSection,
  MapSection,
  PricingSection,
  ProblemAndSolutionSection,
  SideBySideSection,
  StatsSection,
  TestimonialSection,
  TextSection,
  TextColumnsSection,
  UseCasesSection
} from '../sections';

const ComponentMatch = ({ data, galleryNodes }) => {
  const components = {
    ContentfulBasicSection: BasicSection,
    ContentfulBlogPostsSection: BlogPostsSection,
    ContentfulCalendlySection: CalendlySection,
    ContentfulContactSection: ContactSection,
    ContentfulFaqSection: FaqSection,
    ContentfulGallerySection: GallerySection,
    ContentfulHero: HeroSection,
    ContentfulLogoSection: LogoSection,
    ContentfulMapSection: MapSection,
    ContentfulPricingSection: PricingSection,
    ContentfulSideBySideSection: SideBySideSection,
    ContentfulStatsSection: StatsSection,
    ContentfulTestimonialSection: TestimonialSection,
    ContentfulTextColumnsSection: TextColumnsSection,
    ContentfulTextSection: TextSection,
    ContentfulProblemAndSolutionSection: ProblemAndSolutionSection,
    ContentfulFeatureGridSection: FeatureGridSection,
    ContentfulUseCasesSection: UseCasesSection
  };
  const MatchingComponent = components[data.__typename];

  return (
    <MatchingComponent
      data={data}
      {...(data.__typename === 'ContentfulGallerySection' && {
        galleryNodes: galleryNodes
      })}
    />
  );
};

export default ComponentMatch;
