import React from 'react';
import {
  Box,
  BoxProps,
  Flex,
  Heading,
  Center,
  Stack,
  Img,
  SimpleGrid,
  Text,
  useColorModeValue as mode
} from '@chakra-ui/react';
import MainContainer from '../container/container';

import Icon from '../icon/icon';

import { graphql } from 'gatsby';

interface FeatureGridSectionProps {
  data: {
    header?: string;
    features?: Array<{
      id: string;
      header: string;
      paragraph?: {
        paragraph?: string;
      };
      icon: any;
    }>;
  };
}

export const FeatureGridSectionQuery = graphql`
  fragment FeatureGridSection on ContentfulFeatureGridSection {
    header
    features {
      id
      header
      paragraph {
        paragraph
      }
      icon {
        ...Icon
      }
    }
  }
`;

const FeatureGridSection = ({
  data: { header, features }
}: FeatureGridSectionProps) => {
  return (
    <Box as="section" py="24">
      <MainContainer>
        <SimpleGrid
          w="full"
          position="relative"
          columnGap={{ lg: '8' }}
          rowGap={8}
          templateColumns={{ base: '1fr', lg: 'auto 1fr' }}
        >
          {/* Header */}
          <Box w={{ base: 'full', lg: 'md' }} maxW="md">
            <Text textStyle="header1">{header}</Text>
          </Box>

          {/* Feature grid */}
          <SimpleGrid
            w="full"
            templateColumns={{ base: '1fr', lg: '1fr 1fr' }}
            rowGap={8}
          >
            {features.map((feature, idx) => (
              <Stack key={`${feature.id}-${idx}`} spacing={[4, null, 6]}>
                <Box position="relative" h="16" w="16">
                  <Box
                    position="absolute"
                    bg="pri.50"
                    top="4"
                    right="-4"
                    left="-0"
                    bottom="3"
                    color="black"
                    fontSize="2rem"
                    zIndex={0}
                    borderRadius={4}
                    p={2}
                  ></Box>

                  <Center
                    position="relative"
                    zIndex={1}
                    h="full"
                    w="full"
                    textAlign="center"
                  >
                    <Icon
                      {...feature.icon}
                      iconColor="black"
                      iconSize="3.5rem"
                      mx="auto"
                    />
                  </Center>
                </Box>

                <Stack mt="4">
                  <Text as="h3" fontSize="lg" fontWeight="bold">
                    {feature.header}
                  </Text>
                  <Text paddingEnd="6" color="textGray" lineHeight="tall">
                    {feature.paragraph?.paragraph}
                  </Text>
                </Stack>
              </Stack>
            ))}
          </SimpleGrid>
        </SimpleGrid>
      </MainContainer>
    </Box>
  );
};

export default FeatureGridSection;
