// @jsx jsx

import { jsx, SxStyleProp } from 'theme-ui';
import * as CSS from 'csstype';
import React from 'react';
import {
  Flex,
  Button,
  IconButton,
  Box,
  Link,
  Text,
  Heading,
  Stack
} from '@chakra-ui/react';
import { graphql } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

import ReactModal from 'react-modal';
import { useModal } from 'react-modal-hook';
import ReactPlayer from 'react-player/lazy';
import FiPlayCircle from '@meronex/icons/fi/FiPlayCircle';

import MainContainer from '../container/container';
import LinkButton, { LinkButtonProps } from '../link-button/link-button';

interface HeroSectionProps {
  data: {
    header?: string;
    subheader?: string; // Make header h1 style if subheader is set
    paragraph?: {
      paragraph?: string;
    };
    image?: {
      gatsbyImageData: IGatsbyImageData;
      title: string;
      description: string;
    };
    videoUrl?: string;
    imageBlur?: number;
    imageBrightness?: number;
    textAligned?: CSS.Property.TextAlign;
    verticalPlacementOfText?: 'top' | 'middle' | 'bottom';
    imagePosition?: 'background' | 'left' | 'right' | 'bottom';
    backgroundColor?: 'text' | 'primary' | 'secondary' | 'background';
    color?: 'text' | 'primary' | 'secondary' | 'background';
    links?: LinkButtonProps[];
  };
}

export const HeroSectionQuery = graphql`
  fragment HeroSection on ContentfulHero {
    id
    header
    subheader
    paragraph {
      paragraph
    }
    image {
      title
      description
      gatsbyImageData(width: 3500, quality: 100, layout: FULL_WIDTH)
    }
    imageBlur
    imageBrightness
    videoUrl
    textAligned
    verticalPlacementOfText
    imagePosition
    backgroundColor
    color
    links {
      ...Links
    }
  }
`;

const HeroSection = ({
  data: {
    header, // h1 style if subheader is present, otherwise h2
    subheader,
    paragraph,
    image,
    imageBlur,
    imageBrightness,
    videoUrl,
    textAligned,
    verticalPlacementOfText,
    imagePosition,
    backgroundColor,
    color,
    links,
    ...rest
  }
}: HeroSectionProps) => {
  const [showVideoModal, hideVideoModal] = useModal(() => (
    <ReactModal
      isOpen
      onRequestClose={hideVideoModal}
      appElement={document.getElementById('___gatsby')}
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(255, 255, 255, 0.75)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        },
        content: {
          position: 'relative',
          top: 'unset',
          left: 'unset',
          right: 'unset',
          bottom: 'unset',
          border: '1px solid #ccc',
          background: '#fff',
          overflow: 'auto',
          WebkitOverflowScrolling: 'touch',
          borderRadius: '4px',
          outline: 'none',
          padding: '0',
          width: '100%',
          maxWidth: '80vw'
        }
      }}
    >
      <Box className="player-wrapper" width="100%">
        <ReactPlayer
          url={videoUrl}
          width="100%"
          height="100%"
          className="react-player"
          config={{
            youtube: {
              playerVars: { controls: 1, modestBranding: 1 }
            }
          }}
        />
      </Box>
    </ReactModal>
  ));

  const verticalPlacementOfTextToAlignItems = (
    verticalPlacement: HeroSectionProps['data']['verticalPlacementOfText']
  ) => {
    const lookup = { top: 'flex-start', middle: 'center', bottom: 'flex-end' };
    return lookup[verticalPlacement] as CSS.Property.AlignItems;
  };

  const textAlignedToJustifyContent = (
    textAlign: HeroSectionProps['data']['textAligned']
  ) => {
    const lookup = { left: 'flex-start', middle: 'center', right: 'flex-end' };
    return lookup[textAlign] as CSS.Property.JustifyContent;
  };

  const HeroBackground = ({
    imagePosition,
    gatsbyImageData,
    children,
    ...rest
  }) => {
    return (
      <Box
        {...rest}
        style={{
          position: 'relative'
        }}
      >
        {imagePosition === 'background' && gatsbyImageData && (
          <GatsbyImage
            image={gatsbyImageData}
            alt="background"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0
            }}
          />
        )}
        {children}
      </Box>
    );
  };

  const backgroundSx = {
    minHeight: '450px',
    display: 'flex',
    py: 4,
    '&::after, &::before': {
      content: '""',
      filter: `blur(${imageBlur}px) brightness(${imageBrightness})`
    }
  };

  const leftImagePadding = {
    pl: [0, 0, `${(1 / 12) * 100}%`]
  };

  const rightImagePadding = {
    pr: [0, 0, `${(1 / 12) * 100}%`]
  };

  const centerButtons = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  };

  const halfWidth = {
    width: ['100%', '100%', `${(6 / 12) * 100}%`]
  };

  return (
    <Box
      as="section"
      {...(!image ||
        (imagePosition !== 'background' && {
          backgroundColor: backgroundColor
        }))}
    >
      <HeroBackground
        imagePosition={imagePosition}
        gatsbyImageData={image && image.gatsbyImageData}
        sx={backgroundSx}
      >
        <MainContainer
          py={3}
          style={{
            position: 'relative'
          }}
        >
          <Flex
            width={'100%'}
            flexDirection={textAligned === 'left' ? 'row' : 'row-reverse'}
            flexWrap="wrap"
            justifyContent={textAlignedToJustifyContent(textAligned)}
            alignItems={verticalPlacementOfTextToAlignItems(
              verticalPlacementOfText
            )}
            textAlign={textAligned === 'center' ? 'center' : 'left'}
          >
            <Box
              // {...(textAligned !== 'center' && halfWidth)}
              {...(textAligned === 'right' && leftImagePadding)}
              {...(textAligned === 'left' && rightImagePadding)}
              {...(textAligned === 'center' && {
                mx: 'auto'
              })}
            >
              <Stack spacing={4}>
                {header && (
                  <Text as="h1" width="100%" textStyle="hero" color={color}>
                    {header}
                  </Text>
                )}
                {subheader && (
                  <Text
                    as="h2"
                    maxW={textAligned === 'center' ? '4xl' : 'inherit'}
                    textStyle="heroSub"
                    color={color}
                  >
                    {subheader}
                  </Text>
                )}
                {paragraph?.paragraph && (
                  <Text mb={4} variant="heroParagraph" color={color}>
                    {paragraph?.paragraph}
                  </Text>
                )}
              </Stack>
              {videoUrl && imagePosition === 'background' && (
                <Flex {...(textAligned === 'center' && centerButtons)}>
                  <IconButton
                    color="pri.200"
                    icon={<FiPlayCircle></FiPlayCircle>}
                    fontSize={['3rem', '4rem', '8rem', '10rem']}
                    bg="transparent"
                    _hover={{
                      bg: 'transparent',
                      color: 'pri.500'
                    }}
                    aria-label="play video"
                    onClick={showVideoModal}
                  ></IconButton>
                </Flex>
              )}
              {links && links.length > 0 && (
                <Flex
                  // my={[4, 4]}
                  mt={[8, 12]}
                  {...(textAligned === 'center' && centerButtons)}
                >
                  {links.map((link, i) => (
                    <LinkButton
                      key={link.id}
                      {...link}
                      {...(links.length > 1 &&
                        links.length !== i + 1 && { mr: 4 })}
                    />
                  ))}
                </Flex>
              )}
            </Box>
            {image && imagePosition !== 'background' && (
              <Box
                maxWidth="1400px"
                mt={[8, 12]}
                width="100%"
                sx={{
                  overflow: 'hidden',
                  borderRadius: 8,
                  position: 'relative',
                  display: 'inline-block'
                }}
                mx={imagePosition === 'bottom' && 'auto'}
              >
                <GatsbyImage
                  image={image.gatsbyImageData}
                  alt={image.description}
                />
                <div
                  sx={{
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)'
                  }}
                >
                  {videoUrl && (
                    <IconButton
                      color="pri.200"
                      icon={<FiPlayCircle></FiPlayCircle>}
                      fontSize={['3rem', '4rem', '8rem', '10rem']}
                      bg="transparent"
                      _hover={{
                        bg: 'transparent',
                        color: 'pri.500'
                      }}
                      aria-label="play video"
                      onClick={showVideoModal}
                    ></IconButton>
                  )}
                </div>
              </Box>
            )}
          </Flex>
        </MainContainer>
      </HeroBackground>
    </Box>
  );
};

export default HeroSection;
