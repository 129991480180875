import * as React from 'react';
import {
  Flex,
  Box,
  Button,
  Heading,
  Stack,
  HStack,
  Img,
  SimpleGrid,
  Text,
  useColorModeValue as mode,
  ButtonGroup,
  Wrap
} from '@chakra-ui/react';

import { graphql } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import ShowMarkdown from '../shared/ShowMarkdown';

import MainContainer from '../container/container';
import LinkButton, { LinkButtonProps } from '../link-button/link-button';

interface SideBySideSectionProps {
  data?: {
    textOn?: 'left' | 'left';
    backgroundColor?: 'text' | 'primary' | 'secondary' | 'background';
    color?: 'text' | 'primary' | 'secondary' | 'background';
    header?: string;
    paragraph?: {
      paragraph?: string;
    };
    links?: LinkButtonProps[];
    image?: {
      title?: string;
      description?: string;
      gatsbyImageData?: IGatsbyImageData;
    };
    videoLink?: string;
  };
}

export const sideBySideSectionQuery = graphql`
  fragment SideBySideSection on ContentfulSideBySideSection {
    id
    header
    image {
      id
      title
      description
      gatsbyImageData(width: 1000, quality: 100)
    }
    paragraph {
      paragraph
    }
    links {
      ...Links
    }
    videoLink
    backgroundColor
    color
    textOn
  }
`;

const SideBySideSection = ({
  data: {
    header,
    image,
    paragraph,
    links,
    videoLink,
    backgroundColor,
    color,
    textOn,
    ...rest
  }
}: SideBySideSectionProps) => {
  return (
    <Box as="section" py={20}>
      <MainContainer>
        <Box>
          <Flex
            flexDirection={textOn === 'left' ? 'row' : 'row-reverse'}
            flexWrap="wrap"
            alignItems="center"
            justifyContent="center"
            width="100%"
          >
            <Stack
              spacing={6}
              width={['100%', '100%', '60%']}
              {...(textOn === 'left'
                ? { pr: [0, 0, `${(1 / 12) * 100}%`] }
                : { pl: [0, 0, `${(1 / 12) * 100}%`] })}
            >
              <Text textStyle="header1">{header}</Text>

              {paragraph && paragraph.paragraph && (
                <ShowMarkdown markdown={paragraph?.paragraph}></ShowMarkdown>
              )}

              {links && links.length > 0 && (
                <ButtonGroup my={[4, 4]}>
                  {links.map((link, i) => (
                    <LinkButton key={link.id} {...link} />
                  ))}
                </ButtonGroup>
              )}
            </Stack>
            <Box
              width={['100%', '100%', `40%`]}
              mt={[8, 8, 0]}
              textAlign="center"
              rounded="md"
              overflow="hidden"
            >
              <GatsbyImage
                style={{
                  display: 'block',
                  objectFit: 'contain',
                  margin: 'auto'
                }}
                image={image.gatsbyImageData}
                alt={image.description}
              />
            </Box>
          </Flex>
        </Box>
      </MainContainer>
    </Box>
  );
};

export default SideBySideSection;
