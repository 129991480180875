import * as React from 'react';
import CountUp from 'react-countup';
import {
  Box,
  BoxProps,
  Divider,
  Flex,
  useColorModeValue
} from '@chakra-ui/react';

interface StatProps extends BoxProps {
  title: string;
  value: number;
  children?: React.ReactNode;
  accentColor?: string;
  animationDuration?: number;
  decimalPlaces?: number;
  numberPrefix?: string;
  numberSuffix?: string;
}
export const Stat = (props: StatProps) => {
  const {
    title,
    value,
    children,
    accentColor,
    animationDuration,
    decimalPlaces,
    numberPrefix,
    numberSuffix,
    ...rest
  } = props;
  return (
    <Box {...rest}>
      <Flex as="dl" direction="column-reverse">
        <Box textStyle="accentHeader">{title}</Box>

        <Box
          order={1}
          as="dd"
          fontSize={{ base: '4xl', md: '5xl' }}
          // bgGradient={accentColor}
          // backgroundClip="text"
          fontWeight="bold"
        >
          <CountUp
            end={value}
            duration={animationDuration}
            decimals={decimalPlaces}
            prefix={numberPrefix}
            suffix={numberSuffix}
            separator=","
          />
        </Box>
      </Flex>
      {children && (
        <>
          <Divider
            aria-hidden
            my="4"
            borderWidth="2px"
            borderColor={'pri.50'}
          />
          <Box
            color={useColorModeValue('brandSubtle', 'whiteAlpha.700')}
            fontWeight="medium"
          >
            {children}
          </Box>
        </>
      )}
    </Box>
  );
};
