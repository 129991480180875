import React, { ReactNode } from 'react';

import {
  Button as ChakraButton,
  ButtonProps as ChakraButtonProps,
  Link as ChakraLink,
  LinkProps as ChakraLinkProps
} from '@chakra-ui/react';
import { graphql } from 'gatsby';

export interface LinkButtonProps {
  id?: string;
  text?: string;
  link?: string;
  openLinkInNewTab?: boolean;
  type?: 'link' | 'button';
  variant?: 'primary' | 'secondary' | 'background';
  children?: ReactNode;
}

export const linkButtonQuery = graphql`
  fragment Links on ContentfulLinkButton {
    id
    text
    link
    openLinkInNewTab
    type
    variant
  }
`;

const Button = ({
  text,
  link,
  variant,
  openLinkInNewTab,
  children,
  ...rest
}: LinkButtonProps & Omit<ChakraButtonProps, 'css' | 'type'>) => {
  const SCHEME_LOOKUP = {
    primary: 'pri',
    secondary: 'pri',
    background: 'gray'
  };

  const VARIANT_LOOKUP = {
    primary: 'solid',
    secondary: 'outline',
    background: 'solid'
  };
  return (
    <ChakraButton
      {...(link && { as: 'a', href: link })}
      colorScheme={SCHEME_LOOKUP[variant]}
      variant={VARIANT_LOOKUP[variant]}
      {...(openLinkInNewTab && { target: '_blank', rel: 'noreferrer' })}
      {...rest}
    >
      {text}
      {children}
    </ChakraButton>
  );
};

const Link = ({
  text,
  link,
  variant,
  openLinkInNewTab,
  children,
  ...rest
}: LinkButtonProps & Omit<ChakraLinkProps, 'css'>) => {
  return (
    <ChakraLink
      variant={`link.${variant}`}
      href={link}
      {...(openLinkInNewTab && { target: '_blank', rel: 'noreferrer' })}
      {...rest}
    >
      {text}
      {children}
    </ChakraLink>
  );
};

const LinkButton = ({
  text,
  link,
  type,
  variant,
  openLinkInNewTab,
  children,
  ...rest
}: LinkButtonProps &
  Omit<ChakraButtonProps, 'css' | 'type'> &
  Omit<ChakraLinkProps, 'css'>) => {
  let linkOrButton = null;
  switch (type && type.toLowerCase()) {
    case 'button':
      linkOrButton = (
        <Button {...{ text, link, variant, openLinkInNewTab, ...rest }}>
          {children}
        </Button>
      );
      break;

    default:
      linkOrButton = (
        <Link {...{ text, link, variant, openLinkInNewTab, ...rest }}>
          {children}
        </Link>
      );
      break;
  }
  return linkOrButton;
};

export default LinkButton;
