import React from 'react';
import { graphql } from 'gatsby';
import { Box, Heading, Text } from 'rebass';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion';
import Marked from 'marked';

import MainContainer from '../container/container';
import { useThemeUI } from 'theme-ui';
import './faq-section.css';

export const FaqSectionQuery = graphql`
  fragment FaqSection on ContentfulFaqSection {
    header
    questionsAndAnswers {
      question
      answer {
        answer
      }
    }
    backgroundColor
  }
`;

interface FaqSectionProps {
  data: {
    header: string;
    questionsAndAnswers: Array<{
      question: string;
      answer: {
        answer: string;
      };
    }>;
    backgroundColor: string;
  };
}

const FaqSection = ({
  data: { header, questionsAndAnswers, backgroundColor }
}: FaqSectionProps) => {
  const { theme } = useThemeUI();
  return (
    <Box as="section" className="faq-section" mb={[5, 6]}>
      <Box
        width="100%"
        textAlign="center"
        pt={5}
        backgroundColor={backgroundColor}
      >
        <MainContainer display="block">
          <Box mb={5} mx="auto" width="100%" px={[4, 4, 4, 6]}>
            <Heading variant="heading" mt={0} mb={4}>
              {header}
            </Heading>
            <Accordion allowZeroExpanded allowMultipleExpanded>
              {questionsAndAnswers.map((questionAndAnswer, i) => (
                <AccordionItem key={i}>
                  <Box
                    py={4}
                    sx={{
                      borderTopStyle: 'solid',
                      borderTopColor: 'brandSubtle',
                      borderTopWidth: '1px'
                    }}
                  >
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <Text
                          variant="body"
                          fontWeight="bold"
                          textAlign="left"
                          mb={0}
                        >
                          {questionAndAnswer.question}
                        </Text>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <Text
                        variant="renderedMarkdown"
                        color="textGray"
                        textAlign="left"
                        mb={-3}
                        dangerouslySetInnerHTML={{
                          __html: Marked(questionAndAnswer?.answer?.answer, {
                            breaks: true
                          })
                        }}
                      />
                    </AccordionItemPanel>
                  </Box>
                </AccordionItem>
              ))}
            </Accordion>
          </Box>
        </MainContainer>
      </Box>
    </Box>
  );
};

export default FaqSection;
