import React from 'react';
import { graphql } from 'gatsby';
import { IGatsbyImageData, GatsbyImage } from 'gatsby-plugin-image';

import { Carousel } from 'react-responsive-carousel';
import {
  Box,
  Text,
  Grid,
  GridItem,
  HStack,
  Stack,
  useColorModeValue as mode
} from '@chakra-ui/react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import MainContainer from '../container/container';

interface TestimonialSectionProps {
  data: {
    testimonials?: Array<{
      id: string;
      quote?: {
        quote?: string;
      };
      attributionName?: string;
      attributionTitle?: string;
      image?: {
        gatsbyImageData: IGatsbyImageData;
        title: string;
        description: string;
      };
    }>;
  };
}

export const TestimonialSectionQuery = graphql`
  fragment TestimonialSection on ContentfulTestimonialSection {
    id
    testimonials {
      id
      quote {
        quote
      }
      attributionName
      attributionTitle
      image {
        title
        description
        gatsbyImageData(height: 300, quality: 100)
      }
    }
  }
`;

const outlineSx = {
  borderStyle: 'solid',
  borderWidth: '2px',
  borderColor: 'background100',
  rounded: 'md',
  backgroundColor: 'white'
} as const;

const TestimonialSection = ({
  data: { testimonials }
}: TestimonialSectionProps) => {
  return (
    <Box as="section" py={40} overflow="hidden">
      <MainContainer px={0} width="100%" maxWidth="100%">
        <Carousel
          autoPlay
          centerMode
          centerSlidePercentage={50}
          infiniteLoop
          showThumbs={false}
          showStatus={false}
          showIndicators={false}
          interval={8000}
          transitionTime={1000}
        >
          {testimonials.map((testimonial, i) => (
            <Box key={testimonial.id} h="full" px={[2, null, 8]}>
              <Grid
                alignItems="flex-start"
                height="100%"
                px={[4, null, 8]}
                sx={{
                  ...outlineSx,
                  gridAutoFlow: ['row', 'row', 'row', 'column'],
                  gridTemplateRows: [
                    'auto 1fr',
                    'auto 1fr',
                    'auto 1fr',
                    'none'
                  ],
                  gridTemplateColumns: [null, null, null, '1.5fr 1fr'],
                  justifyContent: 'center',
                  rowGap: 32,
                  columnGap: 32,
                  '&::after': {
                    content: '""',
                    display: ['none', 'block'],
                    position: 'absolute',
                    top: 'calc(50% - 10px)',
                    right: '-10px',
                    width: '20px',
                    height: '20px',
                    rounded: 'md',
                    backgroundColor: 'background100'
                  }
                }}
                py={[4, null, 8]}
                mx={[2, 3, 4]}
              >
                {testimonial.image && (
                  <Box overflow="hidden" rounded="md">
                    <GatsbyImage
                      objectFit="cover"
                      style={{
                        display: 'block',
                        width: '100%'
                      }}
                      alt={testimonial.attributionName}
                      image={testimonial.image.gatsbyImageData}
                      // imgStyle={{ objectFit: 'contain' }}
                    />
                  </Box>
                )}
                <GridItem
                  mx="auto"
                  textAlign="left"
                  minW={0}
                  position="relative"
                >
                  <Text fontSize={48} lineHeight="1rem">
                    &ldquo;
                  </Text>
                  <Text textStyle="body">{testimonial.quote.quote}</Text>

                  <HStack
                    mt="4"
                    alignItems="flex-end"
                    spacing={['2', null, '0']}
                  >
                    <Stack spacing={0}>
                      <Text
                        as="cite"
                        fontStyle="normal"
                        fontSize="md"
                        fontWeight="extrabold"
                      >
                        {testimonial.attributionName}
                      </Text>
                      <Text
                        textStyle="accentHeader"
                        color={mode('gray.600', 'gray.400')}
                      >
                        {testimonial.attributionTitle}
                      </Text>
                    </Stack>
                  </HStack>
                </GridItem>
              </Grid>
            </Box>
          ))}
        </Carousel>
      </MainContainer>
    </Box>
  );
};

export default TestimonialSection;
