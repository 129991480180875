import {
  Badge,
  Stack,
  HStack,
  useColorModeValue as mode,
  Text
} from '@chakra-ui/react';
import * as React from 'react';

interface BlogMetaProps {
  type: 'article' | 'webinar' | 'video';
  estReadTime: number;
  createdAt: string;
}

const colors: Record<BlogMetaProps['type'], string> = {
  article: 'blue.100',
  webinar: 'red.600',
  video: 'sec.700'
};

export const BlogMeta = (props: BlogMetaProps) => {
  const { type, estReadTime, createdAt } = props;

  const blogDate = new Date(createdAt);
  const month = new Intl.DateTimeFormat('en-US', {
    month: 'short'
  }).format(blogDate);
  const day = blogDate.getDate();
  const year = blogDate.getFullYear();

  return (
    <Stack
      direction={{ base: 'column', md: 'row' }}
      fontSize="xs"
      justifyContent="space-between"
    >
      <Badge
        alignSelf="flex-start"
        bg={colors[type]}
        variant="solid"
        color="gray.500"
        fontSize="xs"
      >
        {type}
      </Badge>
      <HStack flex="1" justifyContent="space-between">
        <Text
          color={mode('gray.400', 'gray.200')}
        >{`${estReadTime} min read`}</Text>
        <Text
          ml="auto"
          color={mode('gray.400', 'gray.200')}
        >{`${month} ${day}, ${year}`}</Text>
      </HStack>
    </Stack>
  );
};
