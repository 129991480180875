import {
  Box,
  BoxProps,
  Heading,
  Text,
  Link,
  useColorModeValue as mode
} from '@chakra-ui/react';
import { graphql, Link as GatsbyLink } from 'gatsby';
import * as React from 'react';
import { BlogAuthor } from './BlogAuthor';
import { BlogMedia } from './BlogMedia';
import { BlogMeta } from './BlogMeta';
import { IGatsbyImageData } from 'gatsby-plugin-image';

export interface IBlogPost {
  id: string;
  slug: string;
  metaTitle: string;
  metaDescription: string;
  estReadTime: number;
  metaImage?: {
    gatsbyImageData: IGatsbyImageData;
    title: string;
    description: string;
  };
  createdAt: string;

  author: {
    name: string;
    image: {
      gatsbyImageData: IGatsbyImageData;
      title: string;
      description: string;
    };
    title: string;
  };
}

interface BlogCardProps extends BoxProps {
  data: IBlogPost;
}

export const BlogCard = (props: BlogCardProps) => {
  const { data, ...rest } = props;

  const {
    id,
    createdAt,
    author,
    metaTitle,
    metaDescription,
    metaImage,
    slug,
    estReadTime
  } = data;

  return (
    <Box {...rest}>
      <BlogMedia image={metaImage} slug={slug} />
      <Box mt={{ base: '4', md: '6' }}>
        <BlogMeta
          type={'article'}
          estReadTime={estReadTime}
          createdAt={createdAt}
        />
        <Box mb="6">
          <Link as={GatsbyLink} to={slug}>
            <Heading
              size="md"
              pt={{ base: '4', md: '6' }}
              pb={{ base: '0', md: '2' }}
            >
              {metaTitle}
            </Heading>
          </Link>

          <Text
            noOfLines={3}
            color={mode('gray.600', 'gray.400')}
            lineHeight="tall"
          >
            {metaDescription}
          </Text>
        </Box>
        {author && (
          <BlogAuthor
            name={author.name}
            image={author.image}
            role={author.title}
          />
        )}
      </Box>
    </Box>
  );
};
