// @jsx jsx

import { jsx, useThemeUI } from 'theme-ui';
import React, { ReactNode, useEffect, useState } from 'react';
import { graphql } from 'gatsby';

import { IconContext } from '@meronex/icons';

export interface IconProps {
  iconKey?: string;
  iconColor?: string;
  iconSize?: string;
  children?: ReactNode;
}

export const iconQuery = graphql`
  fragment Icon on ContentfulIcon {
    iconKey
    iconColor
  }
`;

const Icon = ({
  iconKey,
  iconColor,
  iconSize = '2rem',
  children
}: IconProps) => {
  const context = useThemeUI();
  const [iconComponent, setIconComponent] = useState(null);
  useEffect(() => {
    const getIcon = async () => {
      const iconImport = await import(`@meronex/icons/fi/${iconKey}`);
      setIconComponent(iconImport);
    };

    getIcon();
  }, [setIconComponent]);

  if (!iconComponent) {
    return null;
  } else {
    const Component = iconComponent.default;

    return (
      <IconContext.Provider
        value={{
          size: iconSize,
          color: context.theme.colors[iconColor] as any
        }}
      >
        <Component />
      </IconContext.Provider>
    );
  }
};

export default Icon;
